import React, { useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col } from "react-bootstrap";
import {
  faChevronCircleRight,
  faChevronCircleLeft,
} from "@fortawesome/free-solid-svg-icons";
import "./ScrollController.css"

export const InfiniteScroller = ({ images }) => {
  return (
    <div className="carousel">
      <div className="carousel-track">
        {images.concat(images).map((image, index) => (
          <img className="px-5" key={index} src={image} alt={`Slide ${index}`} />
        ))}
      </div>
    </div>
  );
};

export const ScrollController = ({ children, align = "start" }) => {
  const scrollElement = useRef(null);

  const handleScrollMove = (ele, isRight) => {
    let currentBoundary = ele.current.scrollLeft;

    let moveTo = isRight ? currentBoundary + 500 : currentBoundary - 500;

    ele.current.scrollTo({ left: moveTo, behavior: "smooth" });
  };

  return (
    <div style={{ position: "relative" }}>
      <div
        style={{
          display: "flex",
          overflow: "scroll",
          scrollSnapType: "x mandatory",
          alignItems: align,
        }}
        className="scroller"
        ref={scrollElement}
      >
        {children}
        <section
          style={{
            position: "absolute",
            left: 0,
            top: "40%",
          }}
          className="scroll-controller"
        >
          <FontAwesomeIcon
            icon={faChevronCircleLeft}
            size="2x"
            style={{
              color: "#f4f5f5",
              backgroundColor: "#7f7a76",
              borderRadius: "50%",
              opacity: 0.8,
            }}
            onClick={() => {
              handleScrollMove(scrollElement, false);
            }}
          />
        </section>
        <section
          style={{ position: "absolute", right: "2%", top: "40%" }}
          className="scroll-controller"
        >
          <FontAwesomeIcon
            icon={faChevronCircleRight}
            size="2x"
            style={{
              color: "#f4f5f5",
              backgroundColor: "#7f7a76",
              borderRadius: "50%",
              opacity: 0.8,
            }}
            onClick={() => {
              handleScrollMove(scrollElement, true);
            }}
          />
        </section>
      </div>
    </div>
  );
};

export const ScrollControllerItem = ({ children }) => {
  return (
    <Col
      xs="auto"
      style={{
        scrollSnapAlign: "start",
        scrollSnapStop: "always",
      }}
      className="px-1"
    >
      {children}
    </Col>
  );
};

export const ProductScroller = ({ children, gap = 1, padding = "0", width, height }) => {
  const scrollRef = useRef(null);

  const handleScrollMove = (isRight) => {
    if (!scrollRef.current) return;

    const ele = scrollRef.current;
    let currentBoundary = ele.scrollLeft;
    let moveTo = isRight ? currentBoundary + 500 : currentBoundary - 500;

    ele.scrollTo({ left: moveTo, behavior: "smooth" });
  };

  return (
    <div
      style={{
        position: "relative",
        ...(width && { width: width }),
        ...(height && { height: height })
      }}
    >
      <div
        ref={scrollRef}
        className="scroller"
        style={{
          display: "flex",
          flexDirection: "row",
          overflowX: "auto",
          scrollSnapType: "x mandatory",
          padding: padding,
          gap: `${gap}px`,
          WebkitOverflowScrolling: "touch",
          overscrollBehaviorX: "contain",
          touchAction: "pan-x",
          msOverflowStyle: "none"
        }}
      >
        <div
          onClick={() => handleScrollMove(false)}
          className="scroller-prev-container d-none d-sm-flex"
          style={{
            position: "absolute",
            left: 0,
            top: "50%",
            transform: "translateY(-50%)",
            width: 40,
            height: 40,
            zIndex: 2,
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(128,128,128,0.5)",
            borderRadius: "50%",
            cursor: "pointer"
          }}
        >
          <div
            style={{
              width: 10,
              height: 10,
              borderLeft: "2px solid #000",
              borderBottom: "2px solid #000",
              transform: "rotate(45deg)",
              marginLeft: "5px"
            }}
          />
        </div>
        <div
          onClick={() => handleScrollMove(true)}
          className="scroller-next-container d-none d-sm-flex"
          style={{
            position: "absolute",
            right: 0,
            top: "50%",
            transform: "translateY(-50%)",
            width: 40,
            height: 40,
            zIndex: 2,
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(128,128,128,0.5)",
            borderRadius: "50%",
            cursor: "pointer"
          }}
        >
          <div
            style={{
              width: 10,
              height: 10,
              borderRight: "2px solid #000",
              borderTop: "2px solid #000",
              transform: "rotate(45deg)",
              marginRight: "5px"
            }}
          />
        </div>
        {children}
      </div>
    </div>
  );
};

export const ProductScrollerItem = ({ children, itemWidth, itemHeight }) => {
  return (
    <div style={{ flexShrink: 0, scrollSnapAlign: "start", marginBottom: "2px" }}>
      <div
        style={{
          position: "relative",
          borderRadius: 8,
          overflow: "hidden",
          width: itemWidth || "fit-content",
          height: itemHeight || "fit-content"
        }}
      >
        {children}
      </div>
    </div>
  );
};
