import React, { useEffect, useRef, useState } from "react";
import {
  Carousel,
  Image,
  Stack,
  Button,
  Row,
  Col,
  Card,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";

import "./Home.css";
import { SearchProductList, SearchCard } from "Components/Search/component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronCircleLeft,
  faChevronCircleRight,
  faGift,
} from "@fortawesome/free-solid-svg-icons";
import { useIntersectionObserver } from "Hook/useIntersectionObs";
import { GiftNoteBanner } from "Components/Note/Gift";
import cBaby from "static/Home/category-baby.png";
import cBirthday from "static/Home/category-birthday.png";
import cChange from "static/Home/category-change.png";
import cElder from "static/Home/category-elder.png";
import cNewHouse from "static/Home/category-newHouse.png";
import cValentine from "static/Home/category-valentine.png";
import l1 from "static/Home/landing-1.png";
import l2 from "static/Home/landing-2.png";
import l3 from "static/Home/landing-3.png";
import l4 from "static/Home/landing-4.png";
import l5 from "static/Home/landing-5.png";
import { Note } from "Components/Note/Note";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  ScrollController,
  ScrollControllerItem,
} from "Components/ScrollController";

const reviews = [
  { title: "", content: "這個我很需要 送禮很大方" },
  { title: "", content: "這個平台對我這個懶人太友好了" },
  { title: "", content: "這真的太方便了" },
  { title: "", content: "送禮真的是門學問呀，覺得這平台真的很方便" },
  { title: "", content: "看起來商品蠻多樣的" },
];

export const Home = () => {
  const CTAButton = ({ to = "/search", isCenter = false }) => {
    return (
      <Stack className={`col-md-3 col-4  ${isCenter && "mx-auto"}`}>
        <Link to={to} className="text-decoration-none text-dark">
          <Button variant="outline-secondary" className="w-100">
            <strong>開始送禮</strong>
          </Button>
        </Link>
      </Stack>
    );
  };

  const ReviewCard = ({ review }) => {
    return (
      <Card
        className="shadow-sm"
        style={{ borderRadius: "12px", height: "180px", width: "15rem" }}
      >
        <Card.Body>
          <Stack>
            <h6 style={{ fontSize: "35px" }}>“</h6>
          </Stack>
          {/* <h5> */}
          <strong>{review.content}</strong>
          {/* </h5> */}
          <Stack>
            <h6 className="ms-auto" style={{ fontSize: "35px" }}>
              “
            </h6>
          </Stack>
        </Card.Body>
      </Card>
    );
  };

  const featureContents = [
    {
      t: (
        <section>
          <h2>送錯禮物讓人捶心肝!</h2>
          <h2>
            <strong>使用NearMe共選送禮送禮,幫助你送對禮物</strong>
          </h2>
          <br />
          <section className="text-muted">
            <p className="text-muted">NearMe邀收禮人一起決定，確保送對禮物!</p>

            <p>
              在你下單後,運送禮物前, NearMe讓你傳送禮物連結給收禮人，先給他
              確認是否合用?
            </p>
            <p>
              如果禮物合用,他可以直接收下。如果不合用，允許他
              自己更改，確保最終收到對的禮物。
            </p>
          </section>
          <CTAButton />
        </section>
      ),
      image: l1,
    },
    {
      t: (
        <section>
          <h2>
            <strong>就算更改禮物，也不超出你的預算</strong>
          </h2>
          <section className="text-muted">
            <p>
              當收禮方選擇更改時,多退你,少他補。如果新禮物較低價,系統會自動刷退
            </p>
            <p>差額給你; 如果較高價,則由收禮人刷信用卡補差額。</p>
          </section>
          <CTAButton />
        </section>
      ),
      image: l2,
    },
    {
      t: (
        <section>
          <h2>
            <strong>由你選擇保密價格</strong>
          </h2>
          <section className="text-muted">
            <p>送出禮物連結前，由你勾選是否保密價格！</p>
            <p>
              一旦你選擇保密，當收禮方打開禮物連結頁，只展示商品資訊，不顯示價格。
            </p>
            <p>
              即使他因為更改禮物而搜尋商品時，也只會顯示需要補刷卡的差額，不顯示商品價格。
            </p>
          </section>
          {/* <h2>
            <strong>為收禮人保密更改內容</strong>
          </h2>
          <section className="text-muted">
            <p>如果收禮人更改禮物，你只知道有更改，但不會知道換成什麼商品</p>
            <p>讓收禮人保有隱私，毫無負擔更改。</p>
          </section> */}
          <CTAButton />
        </section>
      ),
      image: l3,
    },
  ];

  const categoryContents = [
    {
      title: "彌月禮物",
      link: `彌月禮物`,
      image: cBaby,
    },
    {
      title: "生日禮物",
      link: `生日禮物`,
      image: cBirthday,
    },
    {
      title: "交換禮物",
      link: `交換禮物`,
      image: cChange,
    },
    {
      title: "長輩禮物",
      link: `長輩禮物`,
      image: cElder,
    },
    {
      title: "喬遷禮物",
      link: `喬遷禮物`,
      image: cNewHouse,
    },
    {
      title: "節慶禮物",
      link: `節慶禮物`,
      image: cValentine,
    },
  ];

  return (
    <div>
      <section className="text-center text-bold">
        <h1 style={{ fontSize: "75px", fontWeight: "bold" }}>
          你今天
          <span
            style={{
              WebkitBackgroundClip: "text",
              backgroundImage:
                "linear-gradient(to bottom, #005a7f, #0091c9, #7fd1f1)",
              color: "transparent",
            }}
          >
            送禮
          </span>
          了嗎？
        </h1>
        <section style={{ fontSize: "25px" }} className="text-muted">
          <p>使用NearMe共選送禮送禮網站訂購，確保你送對禮物</p>
        </section>
      </section>
      <Stack className="col-4 col-md-1 mx-auto mt-3 mb-5">
        <Link to={"/search"} className="text-decoration-none text-dark">
          <Button className="w-100">開始送禮</Button>
        </Link>
      </Stack>
      <Swiper loop={true} slidesPerView={"auto"}>
        {categoryContents.map((e) => (
          <SwiperSlide
            key={e.title}
            style={{ width: "300px" }}
            className="mx-3"
          >
            <Link to={`/search?q=${e.link}`}>
              <img
                className="mx-3"
                src={e.image}
                width={"300px"}
                style={{ borderRadius: "18px" }}
                alt={`NearMe-${e.title}`}
              />
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>
      {/* <ScrollController>
        {categoryContents.map((e) => (
          <ScrollControllerItem key={e.title}>
            <img
              className="mx-3"
              src={e.image}
              width={"300px"}
              style={{ borderRadius: "18px" }}
              alt={e.title}
            />
          </ScrollControllerItem>
        ))}
      </ScrollController> */}
      <br />
      {/* <section className="p-5" style={{backgroundColor: 'gray',borderRadius: '18px',color:'white'}}> */}

      {featureContents.map((e, idx) => (
        <Row className="align-items-center my-5" key={idx}>
          <Col
            className="mt-3 mt-md-0"
            xs={{ span: "12", order: "2" }}
            md={{ span: "6", order: idx % 2 === 0 ? 1 : 2 }}
          >
            {e.t}
          </Col>
          <Col
            xs={{ span: "12", order: "1" }}
            md={{ span: "6", order: idx % 2 === 0 ? 2 : 1 }}
          >
            <Image src={e.image} width="100%" />
          </Col>
        </Row>
      ))}
      {/* </section> */}

      <hr />
      <Stack className="col-md-8 mx-auto my-5">
        <Image src={l4} alt="" />
        <br />
        <section className="text-center">
          <h2>
            <strong>對方逾期未決定,自動取消,你無需費心處理</strong>
          </h2>
          <section className="text-muted">
            <p>
              如果10天內，對方未點擊連結(選擇收下或更改)，將自動取消禮物及取
              消扣款，不需任何處理。
            </p>
            <p>而且，在他決定之前，你隨時可以取消禮物及退款。</p>
          </section>
        </section>
        <CTAButton isCenter={true} />
      </Stack>
      <hr />
      <Stack className="col-md-8 mx-auto my-5">
        <Image src={l5} alt="" />
        <br />
        <section className="text-center">
          <h2>
            <strong>跨平台選物，多樣選擇，一站挑好</strong>
          </h2>
          <section className="text-muted">
            <p>NearMe商品支援多元來源包括momo、Pinkoi、Line禮物，一站挑好更輕鬆。</p>
          </section>
        </section>
        <CTAButton isCenter={true} />
      </Stack>
      <br />
      <section className="reviewsm py-3">
        <h4>
          <strong>看看其他人怎麼說</strong>
        </h4>
        <ScrollController>
          {reviews.map((e) => (
            <ScrollControllerItem key={e.content}>
              <ReviewCard review={e} />
            </ScrollControllerItem>
          ))}
        </ScrollController>
      </section>
    </div>
  );
};

const H = () => {
  const recommendWords = [
    { title: "中秋特輯", keyword: "中秋" },
    { title: "父親節特輯", keyword: "父親節" },
    { title: "耳飾", keyword: "耳飾" },
    { title: "項鍊", keyword: "項鍊" },
    { title: "巧克力", keyword: "巧克力" },
    { title: "手錶", keyword: "手錶" },
    { title: "行李箱", keyword: "行李箱" },
    { title: "保健食品", keyword: "人參液" },
  ];

  const HomeRecommend = ({ title, qWord }) => {
    const elementRef = useRef(null);
    const { entry, ob } = useIntersectionObserver(elementRef, {
      rootMargin: "0px 0px 720px 0px",
      freezeOnceVisible: false,
    });

    useEffect(() => {
      if (entry && entry.isIntersecting) ob.disconnect();
    }, [entry]);

    return (
      <section ref={elementRef} style={{ minHeight: "360px" }}>
        <h5>
          <Link
            className="text-decoration-none text-dark"
            to={`/search?q=${qWord}`}
          >
            {title}
          </Link>
          <FontAwesomeIcon icon={faGift} className="text-primary" />
        </h5>
        {entry?.isIntersecting && (
          <SearchProductList
            qText={qWord}
            horizontal
            morePage={false}
            pFilter={false}
          />
        )}
      </section>
    );
  };

  return (
    <div>
      <div>
        <Row>
          <Col xs={{ span: "12", order: "2" }}>title</Col>
          <Col xs={{ span: "12", order: "1" }}>image</Col>
        </Row>
      </div>
      {/* Banner */}
      {/* Desktop Banner */}
      {/* <Carousel className="home-banner-desktop py-2">
        {[
          {
            link: "https://blog.nearmespecials.com/tutorial-gift",
            image:
              "https://nearme-stores.s3.ap-northeast-1.amazonaws.com/official/banner/client-home-banner01-pc.png",
          },
          {
            link: "https://blog.nearmespecials.com/giftbiz/",
            image:
              "https://nearme-stores.s3.ap-northeast-1.amazonaws.com/official/banner/client-home-banner02-pc.png",
          },
        ].map((e) => (
          <Carousel.Item key={e.image}>
            <Image
              src={e.image}
              onClick={() => {
                if (e.link) window.open(e.link);
              }}
              alt="NearMe"
              key={e.image}
              className="home-banner-item"
              style={{ maxHeight: "50vh", width: "100vw", objectFit: "cover" }}
            />
          </Carousel.Item>
        ))}
      </Carousel>

      <Carousel className="home-banner-mobile py-2">
        {[
          {
            link: "https://blog.nearmespecials.com/tutorial-gift",
            image:
              "https://nearme-stores.s3.ap-northeast-1.amazonaws.com/official/banner/client-home-banner01-mobile.png",
          },
          {
            link: "https://blog.nearmespecials.com/giftbiz/",
            image:
              "https://nearme-stores.s3.ap-northeast-1.amazonaws.com/official/banner/client-home-banner02-mobile.png",
          },
        ].map((e) => (
          <Carousel.Item key={e.image}>
            <Image
              src={e.image}
              onClick={() => {
                if (e.link) window.open(e.link);
              }}
              alt="NearMe"
              key={e.image}
              className="home-banner-item"
              style={{ maxHeight: "50vh", width: "100vw", objectFit: "cover" }}
            />
          </Carousel.Item>
        ))}
      </Carousel> */}
      <Stack className="col-md-8 mx-auto">
        <Carousel
          indicators={false}
          prevIcon={
            <FontAwesomeIcon
              icon={faChevronCircleLeft}
              className="text-dark bg-white"
              style={{ borderRadius: "50%" }}
              size="2x"
            />
          }
          nextIcon={
            <FontAwesomeIcon
              icon={faChevronCircleRight}
              className="text-dark bg-white"
              style={{ borderRadius: "50%" }}
              size="2x"
            />
          }
        >
          {[
            {
              link: "https://blog.nearmespecials.com",
              image:
                "https://nearme-stores.s3.ap-northeast-1.amazonaws.com/official/banner/client-home-banner01.png",
            },
            {
              link: "https://blog.nearmespecials.com",
              image:
                // "https://nearme-stores.s3.ap-northeast-1.amazonaws.com/official/banner/receive-gift-tut.png",
                "https://nearme-stores.s3.ap-northeast-1.amazonaws.com/official/banner/client-home-banner02.png",
            },
            {
              link: "https://blog.nearmespecials.com",
              image:
                "https://nearme-stores.s3.ap-northeast-1.amazonaws.com/official/banner/client-home-banner03.png",
            },
          ].map((e) => {
            return (
              <Carousel.Item key={e.image}>
                <Image
                  src={e.image}
                  onClick={() => {
                    if (e.link) window.open(e.link);
                  }}
                  alt="NearMe"
                  key={e.image}
                  width={"100%"}
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                    borderRadius: "12px",
                    objectFit: "cover",
                  }}
                />
              </Carousel.Item>
            );
          })}
        </Carousel>
      </Stack>
      <br />

      <Stack className="text-center d-none" gap={2}>
        <h2
          style={{
            fontSize: "3.75rem",
          }}
        >
          <strong className="search-text-gradient">NearMe</strong>
        </h2>
        {/* <h2 style={{ fontWeight: 700 }}>買錯禮物？？別擔心</h2> */}
        <h2 style={{ fontWeight: 700 }} className="search-text-gradient">
          各大電商商品，<span className="text-primary">輕鬆換</span>
        </h2>
        {/* <strong>
          搜尋或貼上商品連結(https://www.pinkoi.com/product/WuUPB5Fz)
        </strong> */}

        <Link to="/FAQ" className="mx-auto">
          <Button size="sm" variant="outline-dark">
            <strong>深入了解 {">"}</strong>
          </Button>
        </Link>
      </Stack>
      <br />
      <hr />
      {recommendWords.map((e, idx) => (
        <section key={e.title}>
          <HomeRecommend title={e.title} qWord={e.keyword} />
          {idx === 4 && (
            <Stack className="my-5 col-md-8 mx-auto">
              <SearchCard />
            </Stack>
          )}

          {idx === 1 && (
            <Stack className="my-5 col-md-8 mx-auto">
              <GiftNoteBanner />
            </Stack>
          )}
        </section>
      ))}
    </div>
  );
};
