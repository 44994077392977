import { useEffect, useMemo, useState, useRef } from "react";
import { OverflowText } from "Components/Text/OverflowText";
import {
  Stack,
  Image,
  Row,
  Col,
  Form,
  Button,
  InputGroup,
  Spinner,
  Card,
  Badge,
  Tabs,
  Tab,
} from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper/modules";
import "swiper/css";
import {
  calLimitAmt,
  calPrice,
  fetchProduct,
  fetchReview,
  scrapeProduct,
  searchProduct,
} from "./utils";
import { useErrorMsg } from "Components/ErrorMsg/ErrorMsg";
import { AmtContrl } from "Components/Order/ScrapeOrder";
import "./component.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronCircleRight,
  faSearch,
  faShoppingCart,
  faStar,
  faTag,
} from "@fortawesome/free-solid-svg-icons";
import {
  useImgaeLoadObserver,
  useIntersectionObserver,
  useIO,
} from "Hook/useIntersectionObs";
import {
  ProductContentDisclaimer,
  RefundNotice,
} from "Components/Note/Product";
import { OrderFAQ, ReceiverFAQ } from "Components/FAQ/FAQ";
import { EmptyPage } from "Components/EmptyPage";
import {
  ProductScroller,
  ProductScrollerItem,
} from "Components/ScrollController";

export const ProductStickyNoteWithButton = ({ product, children }) => {
  return (
    <Stack direction="horizontal" className="my-2">
      <Image src={product.basic.images[0]} className="ownCoupon-image" />
      <div
        className="ownCoupon-content"
        style={{ width: "300px", boxShadow: "5px" }}
      >
        <Row className="align-items-center">
          <Col>
            <strong>{product.basic.productName}</strong>
            <OverflowText text={product.basic.description} max={3} />
          </Col>
          <Col xs="auto">{children}</Col>
        </Row>
      </div>
    </Stack>
  );
};

export const ProductStickyNote = ({ product }) => {
  return (
    <Stack direction="horizontal" className="my-2">
      <Image src={product.basic.images[0]} className="ownCoupon-image" />
      <div
        className="ownCoupon-content"
        style={{ width: "300px", boxShadow: "5px" }}
      >
        <Row className="align-items-center">
          <Col>
            <strong>{product.basic.productName}</strong>
            <OverflowText text={product.basic.description} max={3} />
          </Col>
        </Row>
      </div>
    </Stack>
  );
};

export const SpecSelector = ({ specGroup = [], pickSpec, setPickSpec }) => {
  return (
    <Form.Select
      aria-label="Default select example"
      value={pickSpec}
      onChange={(e) => setPickSpec(e.target.value)}
      className="spec-select"
    >
      <option value="-1" disabled>
        請選擇規格
      </option>
      {specGroup?.map((o, idx) => (
        <option key={idx} value={idx} disabled={o?.stock < 1}>
          {Object.values(o.specs).sort().join(" | ")}
          {o?.stock < 1 && "(售完)"}
        </option>
      ))}
    </Form.Select>
  );
};

export const PlatformReviewList = ({ platform, platformPId }) => {
  const [reviewData, setReviewData] = useState(null);
  useEffect(() => {
    fetchReview(platformPId, platform)
      .then((res) => setReviewData(res))
      .catch((err) => console.log(err));
  }, []);

  const ReviewCard = ({ review }) => {
    return (
      <Card className="mt-2 shadow-sm" style={{ borderRadius: "12px" }}>
        <Card.Body>
          <Row className="justify-content-between">
            <Col xs="auto">{review.buyerNickName}</Col>
            <Col xs="auto" className="text-muted">
              {new Date(review.created).toLocaleDateString()}
            </Col>
          </Row>
          <Stack direction="horizontal" gap={2}>
            <FontAwesomeIcon icon={faStar} style={{ color: "#ffd700" }} />
            {review.score}
            <hr className="vr" />
            {review.spec}
          </Stack>
          <Card.Text className="text-muted">{review?.content}</Card.Text>
        </Card.Body>
      </Card>
    );
  };

  if (!reviewData)
    return (
      <Stack className="text-center text-muted">
        <h4>沒有評論</h4>
      </Stack>
    );

  return (
    <div>
      <Card className="mt-2 shadow-sm" style={{ borderRadius: "12px" }}>
        <Card.Body>
          <Row className="justify-content-between">
            <Col xs="auto">
              <strong>評論總結</strong>
            </Col>
            <Col xs="auto" className="text-muted">
              平均星級: {reviewData.summary.avgStar} / 5
            </Col>
            <Col xs="auto" className="text-muted">
              總評論數: {reviewData.summary.total}
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <hr />
      {reviewData.reviews.map((review, idx) => (
        <ReviewCard key={idx} review={review} />
      ))}
    </div>
  );
};

export const ScrapeProductSearchResult = ({
  data,
  handlePay,
  giftSpreadDiscount,
}) => {
  const {
    productId,
    platform,
    sellerData,
    productName,
    images,
    price,
    specGroup,
    noSpecStock,
    giveaways,
    customOptions,
    detailHtml,
    deliveryEstimated,
  } = data;
  const [pickSpec, setPickSpec] = useState(-1);
  const [pickGiveaways, setPickGiveaways] = useState([]);
  const [pickCustomOptions, setPickCustomOptions] = useState([]);
  const [amount, setAmount] = useState(1);
  const [detailCollapse, setDetailCollapse] = useState(false);
  const errMsg = useErrorMsg();
  const isReceiver = useMemo(() => !!giftSpreadDiscount, [giftSpreadDiscount]);

  // cal final price
  const fPrice = useMemo(
    () => calPrice(pickSpec, amount, { specGroup, price }),
    [pickSpec, amount]
  );

  // cal quantity that is able to buy
  const limitAmount = useMemo(
    () => calLimitAmt(pickSpec, { specGroup, noSpecStock }),
    [pickSpec]
  );

  // change amount when limit amount smaller than choose amount
  useEffect(() => {
    limitAmount < amount && setAmount(1);
  }, [limitAmount]);

  // init giveaways
  useEffect(() => {
    if (!giveaways) return;
    setPickGiveaways(Array(giveaways.length).fill(0));
  }, []);

  // go to pay
  const handleSubmit = () => {
    errMsg.setMsg("");

    try {
      if (specGroup && pickSpec < 0) throw new Error("請選擇規格");

      if (fPrice < 5) throw new Error("價格錯誤");

      if (amount < 1) throw new Error("數量至少1個");

      handlePay({ pickSpec, amount, productData: data });
    } catch (err) {
      errMsg.setMsg(err.message);
    }
  };

  return (
    <>
      {/* <Stack>
        <a href={data?.originalUrl} target="_blank">
          來源商品
        </a>
      </Stack> */}
      <Row xs={1} md={2} className="search-result">
        <Col xs="auto" className="my-2">
          <ProductScroller>
            {images.map((image, idx) => (
              <ProductScrollerItem key={idx}>
                <Image src={image} className="product-img" rounded />
              </ProductScrollerItem>
            ))}
          </ProductScroller>
          {/* <Swiper
            className="search-result-swiper-container"
            centeredSlides={true}
            slidesPerView={"auto"}
            spaceBetween={20}
            pagination={{ dynamicBullets: true, clickable: true }}
            navigation={{
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
              clickable: true,
            }}
            modules={[Pagination, Navigation]}
          >
            {images.map((image, idx) => (
              <SwiperSlide key={idx} className="product-img-container">
                <Image src={image} className="product-img" rounded />
              </SwiperSlide>
            ))}
          </Swiper> */}
        </Col>
        <Col className="my-2">
          <errMsg.MsgAlert />
          <Stack gap={2}>
            <h5 className="text-primary">{sellerData.name}</h5>
            <h5>{productName}</h5>
            {isReceiver && (
              <span>
                {fPrice - giftSpreadDiscount > 0 ? (
                  <Badge bg="primary">商品差額</Badge>
                ) : (
                  <Badge bg="success">免費更換</Badge>
                )}
              </span>
            )}
            <section className="price-sec large-total-price">
              {/* {difPriceGroup && } */}
              {isReceiver ? (
                <>
                  <strong>
                    NT$
                    {fPrice - giftSpreadDiscount > 0
                      ? fPrice - giftSpreadDiscount
                      : 0}
                  </strong>
                </>
              ) : (
                <strong>NT${fPrice}</strong>
              )}
            </section>
            {specGroup && (
              <SpecSelector
                specGroup={specGroup}
                pickSpec={pickSpec}
                setPickSpec={setPickSpec}
              />
            )}
            <AmtContrl
              value={amount}
              max={limitAmount}
              mCb={() => {
                if (amount - 1 < 1) return;
                setAmount((pre) => pre - 1);
              }}
              pCb={() => {
                if (amount + 1 > limitAmount) return;
                setAmount((pre) => pre + 1);
              }}
            />
          </Stack>
          <section className="text-muted my-3">
            <small>
              預估將於確定收禮後的{deliveryEstimated?.startAfterDays}~
              {deliveryEstimated?.estimatedRangeDay +
                deliveryEstimated?.startAfterDays}
              天內出貨，真實出貨日以實際寄出為主
            </small>
          </section>
          <Stack className="ms-auto" gap={2}>
            <Button
              className="go-to-pay-btn"
              variant="primary"
              onClick={handleSubmit}
              disabled={limitAmount < 1}
            >
              <FontAwesomeIcon icon={faShoppingCart} />{" "}
              {`${
                limitAmount === 0
                  ? "完售"
                  : isReceiver
                  ? "更改禮物"
                  : "購買禮物"
              }`}
            </Button>
          </Stack>
        </Col>
      </Row>
      <br />
      <Tabs fill defaultActiveKey={"p"} className="sticky-top bg-white py-2">
        <Tab eventKey={"p"} title="商品資訊" className="py-3">
          {detailHtml && <ProductDetailSection detailHtml={detailHtml} />}
        </Tab>
        <Tab eventKey={"o"} title="訂單須知" className="py-3">
          <RefundNotice />
          <br />

          {isReceiver ? <ReceiverFAQ /> : <OrderFAQ />}
        </Tab>
        <Tab eventKey={"r"} title="評論" className="py-3">
          <PlatformReviewList platform={platform} platformPId={productId} />
        </Tab>
      </Tabs>
    </>
  );
};
//   {"storeName": storeName, "productName": productName, "allSpec":all_spec, "difPriceGroup":dif, "images": imgs, "price": price}

export const ProductDetailSection = ({ detailHtml }) => {
  const [detailCollapse, setDetailCollapse] = useState(false);
  return (
    <>
      <ProductContentDisclaimer />
      <br />
      <section
        className="product-info-detail style-3"
        style={{
          height: detailCollapse ? "auto" : "250px",
          overflow: "hidden",
        }}
      >
        <div
          dangerouslySetInnerHTML={{
            // __html: detailHtml.replace(/\n/g, "<br>"),
            __html: detailHtml,
          }}
        />
      </section>
      <Stack className="mx-auto text-center" style={{ marginTop: "-10px" }}>
        <h5>
          <Badge
            bg="secondary"
            // text="dark"
            pill
            onClick={() => setDetailCollapse(!detailCollapse)}
          >
            {detailCollapse ? "顯示更少" : "顯示更多"}
          </Badge>
        </h5>
      </Stack>
    </>
  );
};

export const SearchProductBar = ({
  initValue = "",
  searchCb,
  handlePending,
}) => {
  const [typeText, setTypeText] = useState(initValue);
  const [pending, setPending] = useState(false);
  const errMsg = useErrorMsg();

  useEffect(() => {
    if (initValue) handleSubmit(initValue);
  }, []);

  const handleSubmit = async (searchText = typeText) => {
    errMsg.setMsg("");

    try {
      handlePending.setPending(true);
      setPending(true);

      let result;

      if (/[a-zA-Z0-9]{24}/.test(searchText)) {
        let data = await fetchProduct(searchText);
        result = { data, for: "fetch", searchText };
        searchCb(result);
        return;
      }

      if (/https?:\/\/\S+/.test(searchText)) {
        console.log(`into scrape product`);
        let data = await scrapeProduct(searchText);
        result = { data, for: "product", searchText };
        searchCb(result);
        return;
      }

      ["momo", "pinkoi"].forEach((e) => {
        searchProduct(searchText, e).then((data) => {
          result = { data, for: "products", searchText };
          searchCb(result);
        });
      });
    } catch (err) {
      errMsg.setMsg(err.message);
    } finally {
      handlePending.setPending(false);
      setPending(false);
    }
  };

  return (
    <Stack className="col-md-10 mx-auto">
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <InputGroup>
          <Form.Control
            size="lg"
            type="search"
            autoComplete="off"
            placeholder="搜尋(e.g https://momo)"
            onChange={(e) => setTypeText(e.target.value)}
            value={typeText}
            id="search-input"
            className="shadow border border-white"
            style={{
              fontSize: "14px",
              color: "black",
              borderRadius: "18px",
            }}
          />
          <Button
            type="submit"
            disabled={pending}
            variant="primary"
            className="search-btn mx-1"
            style={{ borderRadius: "20px" }}
            // onClick={() => handleSubmit(targetUrl)}
          >
            {pending ? (
              <Spinner animation="border" size="sm" />
            ) : (
              <FontAwesomeIcon icon={faSearch} style={{ color: "#fff" }} />
            )}
          </Button>
        </InputGroup>
        <section className="my-2">
          <errMsg.FormTextAlert />
        </section>
      </Form>
    </Stack>
  );
};

export const PlatformBadge = ({ platform }) => {
  const platformColor = {
    pinkoi: "#10567B",
    momo: "#D62872",
    linegift: "#06C755",
  };

  return (
    <span
      className="badge"
      style={{ backgroundColor: platformColor[platform] }}
    >
      {platform === "linegift" ? "LINE" : platform}
    </span>
  );
};

export const ProductSearchItem = ({ data, cb, giftSpreadDiscount = 0 }) => {
  const [ref, isIntersecting] = useIO({
    rootMargin: "0px 0px 300px 0px",
    threshold: 0.1,
  });

  const finalPrice = useMemo(() => {
    return data.price - giftSpreadDiscount;
  }, [data?.price, giftSpreadDiscount]);

  return (
    <Card
      ref={ref}
      id={data.productLink}
      className="border-0 search-product-item text-dark"
      onClick={() => cb(data)}
    >
      {isIntersecting ? (
        <Image
          src={data.imgs?.[0]}
          className="lazy-load mx-auto search-product-img"
        />
      ) : (
        <Stack className="mx-auto">
          <Spinner />
        </Stack>
      )}

      <Card.Body>
        <small className="two-line-ellipsis">{data.productName}</small>
        {giftSpreadDiscount > 0 ? (
          <>
            <small>
              <Badge bg={finalPrice > 0 ? "primary" : "success"}>
                <FontAwesomeIcon icon={faTag} />
                {finalPrice > 0 ? "需補差額" : "免費更改"}
              </Badge>
            </small>
            <Stack direction="horizontal" gap={1}>
              <h5 style={{ fontWeight: "bold" }}>
                NT$
                {finalPrice > 0
                  ? `${finalPrice}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  : 0}
              </h5>
              {/* <h6 className="text-decoration-line-through text-muted">
                NT${data.price}
              </h6> */}
            </Stack>
          </>
        ) : (
          <Card.Text>
            NT${`${finalPrice}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          </Card.Text>
        )}
      </Card.Body>
    </Card>
  );
};
